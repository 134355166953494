import api from "@/api";
// import { forEach } from "lodash-es";

export default {
  namespaced: true,
  actions: {
    get(_, pagination) {
      return api.$get("/royalties", pagination).then(res => res.data);
    },
    index(_, query) {
      return api.$get("/royalties/query", query).then(res => res.data);
    },
    queryByClient(_, query) {
      return api.$get("/royalties/by-client", query).then(res => res.data);
    },
    queryByMethod(_, query) {
      return api.$get("/royalties/by-method", query).then(res => res.data);
    },
    addPayment(_, data) {
      return api.post("/royalties/add-payment", data).then(res => res.data);
    },
    update(_, royalty) {
      return api.put(`/royalties/${royalty.id}`, royalty).then(res => res.data);
    },
    delete(_, royalty_id) {
      return api.delete(`/royalties/${royalty_id}`).then(res => res.data);
    }
  }
};
