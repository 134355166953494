import api from "@/api";

export default {
  namespaced: true,
  actions: {
    paginate(_, pagination) {
      return api
        .$get("/languages/paginate", pagination, { noPrefix: true })
        .then(res => res.data);
    },
    create(_, language) {
      return api.post("/languages", language).then(res => res.data);
    },
    update(_, language) {
      return api
        .put(`/languages/${language.id}`, language)
        .then(res => res.data);
    },
    delete(_, language_id) {
      return api.delete(`/languages/${language_id}`).then(res => res.data);
    }
  }
};
