<template>
  <div class="preloader" id="preloader" v-show="ebpLoaderState">
    <div id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "preloader",
  computed: {
    ...mapState("layout", ["loader"])
  }
};
</script>

<style></style>
