import api from "@/api";

export default {
  namespaced: true,
  actions: {
    get(_, pagination) {
      return api.$get("/inbound-payments", pagination).then(res => res.data);
    },
    index(_, query) {
      return api.$get("/inbound-payments/query", query).then(res => res.data);
    },
    addPayment(_, data) {
      return api
        .post("/inbound-payments/add-payment", data)
        .then(res => res.data);
    },
    update(_, royalty) {
      return api
        .put(`/inbound-payments/${royalty.id}`, royalty)
        .then(res => res.data);
    },
    delete(_, royalty_id) {
      return api
        .delete(`/inbound-payments/${royalty_id}`)
        .then(res => res.data);
    }
  }
};
