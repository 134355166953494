import api from "@/api";

export default {
  namespaced: true,
  actions: {
    get(_, pagination) {
      return api.$get("/outbound-payments", pagination).then(res => res.data);
    },
    index(_, query) {
      return api.$get("/outbound-payments/query", query).then(res => res.data);
    },
    create(_, data) {
      return api.post("/outbound-payments", data).then(res => res.data);
    },
    createBatch(_, data) {
      return api.post("/outbound-payments/batch", data).then(res => res.data);
    },
    update(_, royalty) {
      return api
        .put(`/outbound-payments/${royalty.id}`, royalty)
        .then(res => res.data);
    },
    delete(_, royalty_id) {
      return api
        .delete(`/outbound-payments/${royalty_id}`)
        .then(res => res.data);
    }
  }
};
