import api from "@/api";

export default {
  namespaced: true,
  actions: {
    get(_, userId) {
      return api.$get(`/users/${userId}/preferences`).then(res => res.data);
    },
    deletePreferencesById(_, userId) {
      return api.delete(`/users/${userId}/preferences`);
    },
    updateCurrent(_, preferences) {
      return api.put("/users/preferences", preferences);
    },
    updatePaymentPreferences(_, preferences) {
      return api.put("/users/preferences/payment", preferences);
    },
    deletePaymentPreferences() {
      return api.delete("/users/preferences/payment");
    }
  }
};
