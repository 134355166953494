export default {
  computed: {
    ebpLoaderState() {
      return this.$store.getters["layout/loader"];
    }
  },
  methods: {
    ebpLoader(loaderState) {
      this.$store.dispatch("layout/changeLoaderValue", { loader: loaderState });
    }
  }
};
