import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);

import guestRoutes from "./routes/guest";
import adminRoutes from "./routes/admin";
import clientRoutes from "./routes/client";

const prefixRoutes = (prefix, routes, role) => {
  return routes.map(route => ({
    ...route,
    path: prefix + "/" + route.path,
    meta: { ...route.meta, role }
  }));
};

const router = new VueRouter({
  mode: "history",
  routes: [
    ...guestRoutes,
    ...adminRoutes(prefixRoutes),
    ...clientRoutes(prefixRoutes)
  ]
});

router.beforeEach((to, from, next) => {
  const authenticated = store.getters["auth/isLogged"];
  const user = store.getters["auth/user"];

  if (to.meta.guest) {
    if (authenticated) {
      return router.push({
        name: user.role !== "client" ? "AdminDashboard" : "ClientDashboard"
      });
    }
  } else {
    if (!authenticated) {
      return router.push({
        name: "Login"
      });
    } else {
      // Legacy password reset
      if (
        process.env.VUE_APP_ENABLE_LEGACY_PASSWORD_RESETS &&
        !store.getters["proxy/isProxied"]
      ) {
        if (
          user.legacy_password &&
          to.name !== "LegacyPasswordReset" &&
          user.role === "client"
        ) {
          return router.push({
            name: "LegacyPasswordReset"
          });
        }
      }
      // Legacy password reset end
    }

    if (user.role !== "client" && to.meta.role === "client")
      return router.push({ name: "AdminDashboard" });

    const url = to.path;
    if (user.role === "client" && url.match("/admin")) {
      return router.push({ name: "ClientDashboard" });
    }
  }

  next();
});

export default router;
