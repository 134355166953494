import api from "@/api";

export default {
  namespaced: true,
  actions: {
    sendCode(_, email) {
      return api
        .post("/auth/forgot-password/send-code", { email })
        .then(res => res.data);
    },
    submitCode(_, { code, email }) {
      return api
        .post("/auth/forgot-password/submit-code", { code, email })
        .then(res => res.data);
    },
    resetPassword(_, { code, email, password }) {
      return api
        .post("/auth/forgot-password/reset-password", { code, email, password })
        .then(res => res.data);
    }
  }
};
