import api from "@/api";
import { groupBy, keyBy } from "lodash-es";

const getMap = (state, prop, mapBy = "id") => {
  const map = {};
  state[prop].forEach(i => (map[i[mapBy]] = i));
  return map;
};

export default {
  namespaced: true,
  state: {
    languages: [],
    bookCategories: [],
    salesChannels: [],
    salesChannelsCategorized: null,
    allSalesChannels: [],
    currencies: [],
    territories: [],
    paymentMethods: [],
    paymentFrequencies: [],
    minimumPaymentBalances: [],
    validCurrencies: [],
    salesChannelsLoading: false,
    salesChannelsHashMap: {}
  },
  getters: {
    languages(state) {
      return state.languages;
    },
    bookCategories(state) {
      return state.bookCategories;
    },
    salesChannels(state) {
      return state.salesChannels;
    },
    salesChannelsCategorized(state) {
      return state.salesChannelsCategorized;
    },
    currencies(state) {
      return state.currencies;
    },
    territories(state) {
      return state.territories;
    },
    paymentMethodsMap(state) {
      return getMap(state, "paymentMethods", "id");
    },
    validCurrencies(state) {
      return state.validCurrencies;
    }
  },
  mutations: {
    SAVE_LANGUAGES(state, languages) {
      state.languages = languages;
    },
    SAVE_CATEGORIES(state, bookCategories) {
      state.bookCategories = bookCategories;
    },
    SAVE_SALES_CHANNELS(state, { channels, all }) {
      if (all) {
        return (state.allSalesChannels = channels);
      }
      state.salesChannels = channels;
      state.allSalesChannels = channels;
      state.salesChannelsCategorized = groupBy(channels, "category");
      state.salesChannelsHashMap = keyBy(channels, "id");
    },
    SAVE_CURRENCIES(state, currencies) {
      state.currencies = currencies;
    },
    SAVE_TERRITORIES(state, territories) {
      state.territories = territories;
    },
    SAVE_PAYMENT_METHODS(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
    SAVE_PAYMENT_FREQUENCIES(state, paymentFrequencies) {
      state.paymentFrequencies = paymentFrequencies;
    },
    SAVE_MINIMUM_PAYMENT_BALANCES(state, minimumPaymentBalances) {
      state.minimumPaymentBalances = minimumPaymentBalances;
    },
    SET_SALES_CHANNEL_LOADING(state, salesChannelsLoading) {
      state.salesChannelsLoading = salesChannelsLoading;
    },
    SAVE_VALID_CURRENCIES(state, validCurrencies) {
      state.validCurrencies = validCurrencies;
    }
  },
  actions: {
    getValidCurrencies({ commit }) {
      return api
        .$get("/valid-currencies", {}, { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit(
            "SAVE_VALID_CURRENCIES",
            res.data.map(c => c.id)
          );
        });
    },
    getLanguages({ commit }) {
      return api
        .$get("/languages", {}, { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_LANGUAGES", res.data);
        });
    },
    getBookCategories({ state, commit }, params = {}) {
      if (state.bookCategories.length && !params.shouldReset) {
        return state.bookCategories;
      }
      return api
        .$get("/book-categories", params, { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_CATEGORIES", res.data);
        });
    },
    setBookCategories({ commit }, cats) {
      commit("SAVE_CATEGORIES", cats);
    },
    getSalesChannels(
      { state, commit },
      { all, wasUpdated, ...params } = {
        all: false
      }
    ) {
      if (state.salesChannels.length && !wasUpdated) return state.salesChannels;
      commit("SET_SALES_CHANNEL_LOADING", true);
      return api
        .$get("/sales-channels", { all, ...params }, { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_SALES_CHANNELS", { channels: res.data, all });
          commit("SET_SALES_CHANNEL_LOADING", false);
          return {
            data: {
              data: res.data
            }
          };
        });
    },
    getCurrencies({ state, commit }) {
      if (state.currencies.length) return state.currencies;
      return api
        .$get("/currencies", {}, { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_CURRENCIES", res.data);
        });
    },
    getTerritories({ state, commit }) {
      if (state.territories.length) return state.territories;
      return api
        .get("/territories/by-continent", { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit(
            "SAVE_TERRITORIES",
            res.data.map(t => ({
              id: t.id,
              label: t.name,
              children: t.territories.map(tt => ({
                id: `${tt.id}-t`,
                label: tt.name
              }))
            }))
          );
        });
    },
    getPaymentMethods({ state, commit }) {
      if (state.paymentMethods.length) return state.paymentMethods;
      return api
        .get("/payment-methods", { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_PAYMENT_METHODS", res.data);
        });
    },
    getPaymentFrequencies({ state, commit }) {
      if (state.paymentFrequencies.length) return state.paymentFrequencies;
      return api
        .get("/payment-frequencies", { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_PAYMENT_FREQUENCIES", res.data);
        });
    },
    getMinimumPaymentBalances({ state, commit }) {
      if (state.minimumPaymentBalances.length)
        return state.minimumPaymentBalances;
      return api
        .get("/minimum-payment-balances", { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_MINIMUM_PAYMENT_BALANCES", res.data);
        });
    }
  }
};
