<template>
  <b-card :class="{ 'ebp-card': true }" v-bind="$attrs">
    <vue-page-transition>
      <span class="loading" v-if="loading">
        <spinner-dots />
      </span>
    </vue-page-transition>
    <slot />
  </b-card>
</template>

<script>
export default {
  name: "ebp-card",
  props: {
    loading: Boolean
  }
};
</script>

<style lang="scss">
.ebp-card {
  position: relative;
  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.98);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    i {
      font-size: 2em !important;
    }
  }
}
</style>
