<template>
  <div class="d-flex justify-content-center ebp-spinner">
    <i
      :class="
        `bx bx-loader bx-spin font-size-16 align-middle mr-2 text-${color}`
      "
    ></i>
  </div>
</template>

<script>
export default {
  name: "spinner",
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<style lang="scss">
.ebp-spinner {
  position: relative;
  i {
    font-size: 2em !important;
    color: $primary;
  }
}
</style>
