import api from "@/api";

export default {
  namespaced: true,
  actions: {
    paginate(_, pagination) {
      return api
        .$get("/territories/paginate", pagination, { noPrefix: true })
        .then(res => res.data);
    },
    create(_, channel) {
      return api.post("/territories", channel).then(res => res.data);
    },
    update(_, channel) {
      return api
        .put(`/territories/${channel.id}`, channel)
        .then(res => res.data);
    },
    delete(_, channel_id) {
      return api.delete(`/territories/${channel_id}`).then(res => res.data);
    }
  }
};
