export default {
  data() {
    return {
      joiOptions: {
        errors: {
          wrap: {
            label: false
          }
        }
      },
      $colors: [
        "#f46a6a",
        "#2561a1",
        "#a1a125",
        "#5fa125",
        "#25a13e",
        "#2590a1",
        "#25a16d",
        "#40b3d6",
        "#5925a1",
        "#a12525",
        "#a1254e",
        "#40d66d",
        "#93d640"
      ]
    };
  },
  computed: {
    yesNo() {
      return ["yes", "no"].map(s => ({
        text: this.$t(s),
        value: s === "yes"
      }));
    }
  },
  methods: {
    addAllOption(options, label = "any") {
      return [
        {
          text: this.$t(label),
          value: null
        }
      ].concat(options);
    },
    addSelectOption(items, key, label, selectText = "select") {
      return [
        {
          text: this.$t(selectText),
          value: null
        }
      ].concat(
        items.map(i => ({
          text: this.$t(label || key ? i[label || key] : i),
          value: key ? i[key] : i
        }))
      );
    },
    optionize(data, key, label) {
      return data.map(item => ({
        text: this.$t(label ? item[label] : item),
        value: key ? item[key] : item
      }));
    }
  }
};
