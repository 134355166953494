import api from "@/api";
import { forEach } from "lodash-es";

export default {
  namespaced: true,
  actions: {
    all(_, pagination) {
      return api
        .$get("/daily-sale-records/all", pagination)
        .then(res => res.data);
    },
    get(_, pagination) {
      return api.$get("/daily-sale-records", pagination).then(res => res.data);
    },
    create(_, { salesRecord, onUploadProgress }) {
      const fd = new FormData();
      forEach(salesRecord, (val, key) => {
        fd.append(key, val);
      });
      return api
        .post("/daily-sale-records", fd, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress
        })
        .then(res => res.data);
    },
    update(_, salesRecord) {
      return api
        .put(`/daily-sale-records/${salesRecord.id}`, salesRecord)
        .then(res => res.data);
    },
    delete(_, saleRecord_id) {
      return api
        .delete(`/daily-sale-records/${saleRecord_id}`)
        .then(res => res.data);
    }
  }
};
