export default {
  namespaced: true,
  state: {
    error: ""
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  getters: {
    get(state) {
      return state.error;
    }
  },
  actions: {
    set({ commit }, { err, timeout }) {
      commit("SET_ERROR", err);
      if (timeout) {
        setTimeout(() => commit("SET_ERROR", ""), timeout);
      }
    }
  }
};
