<template>
  <div class="error-alert">
    <b-alert
      dismissible
      show
      v-if="error"
      :variant="variant"
      @dismissed="dismissed"
      >{{ $t(error) }}</b-alert
    >
  </div>
</template>

<script>
export default {
  name: "error-alert",
  props: {
    timeout: {
      type: Number
    },
    err: String,
    variant: {
      type: String,
      default: "danger"
    }
  },
  computed: {
    error: {
      get() {
        return this.$props.err || this.$store.getters["error/get"];
      },
      set(err) {
        this.$store.dispatch("error/set", {
          err,
          timeout: this.$props.timeout
        });
      }
    }
  },
  methods: {
    dismissed() {
      this.$store.dispatch("error/set", { err: "" });
      this.$emit("update:err", null);
    }
  }
};
</script>

<style></style>
