import axios from "axios";
import queryString from "query-string";
import store from "@/store";
import { forEach } from "lodash-es";

const api = axios.create({
  baseURL:
    process.env.VUE_APP_API_BASE || "https://account.ebookpartnership.com/api",
  headers: {
    Accept: "application/json"
  }
});

api.interceptors.request.use(
  function(config) {
    // Add authorization
    const token = store.getters["auth/token"];
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    // Add prefix based on role
    const user = store.getters["auth/user"];
    if (user && !config.noPrefix) {
      config.url = user.role + config.url;
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  res => {
    const { method } = res.config;

    if (!["get", "options"].includes(method)) {
      window.$vm.$toast.success(window.$vm.successRes(res), "is-success");
    }

    return res;
  },
  function(err) {
    return new Promise((_, reject) => {
      if (err.response.data.errors) {
        forEach(err.response.data.errors, errors =>
          errors.forEach(err => window.$vm.$toast.error(window.$vm.$t(err)))
        );
      } else {
        window.$vm.$toast.error(window.$vm.errorRes(err));
      }

      // Logout if error is unauthenticated
      if (store.state.auth.user && err.response.status === 401) {
        store.dispatch("auth/logout");
        window.location.reload();
      }
      reject(err);
    });
  }
);

api.$get = (url, params = [], config = {}) => {
  return api.get(url + "?" + queryString.stringify(params), config);
};

export default api;
