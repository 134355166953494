export default {
  computed: {
    $role() {
      return this.$store.getters["auth/user"]?.role;
    },
    $isAdmin() {
      return this.$role === "admin";
    }
  }
};
