import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VTooltip from "v-tooltip";
import VueMeta from "vue-meta";
import Vue2Editor from "vue2-editor";
import PortalVue from "portal-vue";
import VueApexCharts from "vue-apexcharts";
import VuePageTransition from "vue-page-transition";
import VueNumber from "vue-number-animation";

// Stoke
import "@/assets/scss/index.scss";
import "vue-toast-notification/dist/theme-default.css";

import VueToast from "vue-toast-notification";

Vue.use(VueToast, {
  position: "bottom-left"
});
Vue.use(VTooltip);
Vue.use(VueMeta);
Vue.use(Vue2Editor);
Vue.use(PortalVue);
Vue.use(VueApexCharts);
Vue.use(VuePageTransition);
Vue.use(VueNumber);
Vue.use(require("vue-pusher"), {
  api_key: process.env.VUE_APP_PUSHER_API_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true
  }
});

import errorMixin from "@/mixins/error";
import colorMixin from "@/mixins/color";
import breakpointMixin from "@/mixins/breakpoint";
import loaderMixin from "@/mixins/loader";
import optionsMixin from "@/mixins/options";
import dateMixin from "@/mixins/date";
import proxyMixin from "@/mixins/proxy";
import numberMixin from "@/mixins/number";
import roleMixin from "@/mixins/role";
import updateAppMixin from "@/mixins/update-app";

Vue.mixin({
  mixins: [
    errorMixin,
    colorMixin,
    breakpointMixin,
    loaderMixin,
    optionsMixin,
    dateMixin,
    proxyMixin,
    numberMixin,
    roleMixin,
    updateAppMixin
  ]
});

import EBPButton from "@/components/EBPButton";
import EBPCard from "@/components/core/ebp-card";
import EBPModal from "@/components/ebp-modal";
import Preloader from "@/components/Preloader";
import Spinner from "@/components/core/Spinner";
import SpinnerDots from "@/components/core/SpinnerDots";
import ErrorAlert from "@/components/error-alert";
import BackButon from "@/components/core/BackButton";
import "./registerServiceWorker";

Vue.component("ebp-button", EBPButton);
Vue.component("ebp-card", EBPCard);
Vue.component("ebp-modal", EBPModal);
Vue.component("preloader", Preloader);
Vue.component("error", ErrorAlert);
Vue.component("spinner", Spinner);
Vue.component("spinner-dots", SpinnerDots);
Vue.component("apexchart", VueApexCharts);
Vue.component("back-button", BackButon);

Vue.config.productionTip = false;

window.$vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
