import api from "@/api";

// Fixtures
import bookFields from "@/fixtures/book-fields";

export default {
  namespaced: true,
  state: {
    fields: bookFields
  },
  getters: {
    fields(state) {
      return state.fields;
    }
  },
  mutations: {
    SAVE_BOOK_DATA(state, { data, total }) {
      state.books = data;
      state.total = total;
    }
  },
  actions: {
    get(_, pagination) {
      return api.$get("/books", pagination).then(res => res.data);
    },
    exportCSV(_, book_ids) {
      return api.post("/books/export-csv", { book_ids }).then(res => res.data);
    },
    importCSV(_, { file, onUploadProgress }) {
      const data = new FormData();
      data.append("file", file);
      return api.post("/books/import-csv", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    getChangesHistory(_, { id, pagination }) {
      return api.$get(`/books/${id}/changes`, pagination).then(res => res.data);
    },
    search(_, params) {
      return api.$get("/books", params).then(res => res.data);
    },
    show(_, id) {
      return api.get(`/books/${id}`).then(res => res.data);
    },
    create(_, book) {
      return api.post("/books", book).then(res => res.data);
    },
    update(_, book) {
      return api.put(`/books/${book.id}`, book).then(res => res.data);
    },
    updateBulkStatuses(_, data) {
      return api.put(`/books/update-bulk-statuses`, data);
    },
    acknowledgeBooksInBulk(_, data) {
      return api.put(`/books/acknowledge-books-in-bulk`, data);
    },
    delete(_, book_id) {
      return api.delete(`/books/${book_id}`).then(res => res.data);
    },
    submit(_, book) {
      return api
        .put(`/books/${book.id}/submit-for-publishing`, { notes: book.notes })
        .then(res => res.data);
    },
    duplicate(_, book_id) {
      return api.post(`/books/${book_id}/duplicate`).then(res => res.data);
    },
    unpublish(_, book_id) {
      return api.put(`/books/${book_id}/unpublish`).then(res => res.data);
    },
    requestUnpublish(_, book_id) {
      return api
        .put(`/books/${book_id}/request-unpublish`)
        .then(res => res.data);
    },
    acknowledgeChanges(_, book_id) {
      return api
        .put(`/books/${book_id}/acknowledge-changes`)
        .then(res => res.data);
    },
    getByDailySales(_, filters) {
      return api
        .$get("/books/get-by-daily-sales", filters)
        .then(res => res.data);
    }
  }
};
