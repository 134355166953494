import api from "@/api";

export default {
  namespaced: true,
  state: {
    user: null,
    token: null
  },

  mutations: {
    setUserData(state, { user, token }) {
      state.user = user;
      state.token = token;
    },
    clearUserData(state) {
      state.user = null;
      state.token = null;
    }
  },
  actions: {
    proxy(_, id) {
      return api.post(`/users/${id}/proxy`).then(res => res.data);
    }
  },
  getters: {
    isProxied: state => !!state.user,
    user: state => state.user,
    token: state => state.token
  }
};
