// Guest routes
const Dashboard = () =>
  import(/* webpackChunkName: "client-dashboard" */ "@/views/admin/Dashboard");

// Users
const ViewUsers = () =>
  import(/* webpackChunkName: "admin-users" */ "@/views/admin/users/View");
const AddUser = () =>
  import(/* webpackChunkName: "admin-add-user" */ "@/views/admin/users/Add");

// Books
const ViewBooks = () =>
  import(/* webpackChunkName: "admin-books" */ "@/views/admin/books/View");
const EditBook = () =>
  import(/* webpackChunkName: "admin-edit-book" */ "@/views/admin/books/Edit");
const ViewBook = () =>
  import(
    /* webpackChunkName: "admin-view-book" */ "@/views/admin/books/ViewBook"
  );
const AddBook = () =>
  import(/* webpackChunkName: "admin-add-book" */ "@/views/admin/books/Add");
const ExportBooks = () =>
  import(
    /* webpackChunkName: "admin-export-books" */ "@/views/admin/books/Export"
  );
const ImportBooks = () =>
  import(
    /* webpackChunkName: "admin-import-books" */ "@/views/admin/books/Import"
  );

// Promotions
const ViewPromotions = () =>
  import(
    /* webpackChunkName: "admin-promotions" */ "@/views/admin/promotions/View"
  );
const EditPromotion = () =>
  import(
    /* webpackChunkName: "admin-edit-promotion" */ "@/views/admin/promotions/Edit"
  );
const AddPromotion = () =>
  import(
    /* webpackChunkName: "admin-add-promotion" */ "@/views/admin/promotions/Add"
  );

// Sales
// Imports
const ViewSaleImports = () =>
  import(
    /* webpackChunkName: "view-imports" */ "@/views/admin/sales/imports/View"
  );

// Records
const ViewSaleRecords = () =>
  import(
    /* webpackChunkName: "view-records" */ "@/views/admin/sales/records/View"
  );

// Royalties
const ViewRoyalties = () =>
  import(
    /* webpackChunkName: "view-royalties" */ "@/views/admin/royalties/View"
  );
const ViewRoyaltyPayments = () =>
  import(
    /* webpackChunkName: "view-payments" */ "@/views/admin/royalties/ViewPayments"
  );
const AddRoyalties = () =>
  import(/* webpackChunkName: "add-royalties" */ "@/views/admin/royalties/Add");

// Royalties
const ViewPayments = () =>
  import(/* webpackChunkName: "view-payments" */ "@/views/admin/payments/View");
const AddPayment = () =>
  import(/* webpackChunkName: "add-payment" */ "@/views/admin/payments/Add");
const AddBatchPayments = () =>
  import(
    /* webpackChunkName: "add-batch-payments" */ "@/views/admin/payments/AddBatch"
  );

const Support = () =>
  import(/* webpackChunkName: "support" */ "@/views/client/Support");

const SupportEdit = () =>
  import(/* webpackChunkName: "supportediter" */ "@/views/admin/SupportEdit");

// ISBN Manager
const ViewIsbns = () =>
  import(/* webpackChunkName: "view-isbns" */ "@/views/admin/isbns/View");

// System
const AdminSalesChannels = () =>
  import(
    /* webpackChunkName: "admin-sales-channels" */ "@/views/admin/system/sales-channels/View"
  );
const AdminCategories = () =>
  import(
    /* webpackChunkName: "admin-categories" */ "@/views/admin/system/categories/View"
  );
const AdminLanguages = () =>
  import(
    /* webpackChunkName: "admin-languages" */ "@/views/admin/system/languages/View"
  );

const AdminCurrencies = () =>
  import(
    /* webpackChunkName: "admin-currencies" */ "@/views/admin/system/currencies/View"
  );
const AdminTerritories = () =>
  import(
    /* webpackChunkName: "admin-territories" */ "@/views/admin/system/territories/View"
  );
const AdminSiteSettings = () =>
  import(
    /* webpackChunkName: "site-settings" */ "@/views/admin/system/SiteSettings"
  );

export default prefixRoutes => {
  return prefixRoutes(
    "/admin",
    [
      {
        name: "AdminDashboard",
        path: "",
        component: Dashboard,
        meta: {
          title: "dashboard",
          layout: "admin"
        }
      },
      {
        name: "AdminUsers",
        path: "users",
        component: ViewUsers,
        meta: {
          title: "view-clients",
          layout: "admin"
        }
      },
      {
        name: "AdminAddUser",
        path: "users/add",
        component: AddUser,
        meta: {
          title: "add-client",
          layout: "admin"
        }
      },
      {
        name: "AdminBooks",
        path: "books",
        component: ViewBooks,
        meta: {
          title: "view-books",
          layout: "admin"
        }
      },
      {
        name: "AdminSupport",
        path: "support",
        component: Support,
        meta: {
          title: "Support",
          layout: "admin"
        }
      },
      {
        name: "AdminSupportEdit",
        path: "support-edit",
        component: SupportEdit,
        meta: {
          title: "support-page-editor",
          layout: "admin"
        }
      },
      {
        name: "AdminAddBook",
        path: "books/add",
        component: AddBook,
        meta: {
          title: "add-book",
          layout: "admin"
        }
      },

      {
        name: "ExportBooks",
        path: "books/export",
        component: ExportBooks,
        meta: {
          title: "export-books",
          layout: "admin"
        }
      },
      {
        name: "ImportBooks",
        path: "books/import",
        component: ImportBooks,
        meta: {
          title: "import-books",
          layout: "admin"
        }
      },
      {
        name: "AdminViewSingleBook",
        path: "books/view/:id",
        component: ViewBook,
        meta: {
          title: "Book Summary",
          layout: "admin"
        }
      },
      {
        name: "AdminEditBook",
        path: "books/:id",
        component: EditBook,
        meta: {
          title: "edit-book",
          layout: "admin"
        }
      },
      {
        name: "AdminSiteSettings",
        path: "system/site-settings",
        component: AdminSiteSettings,
        meta: {
          title: "site-settings",
          layout: "admin"
        }
      },
      {
        name: "AdminSalesChannels",
        path: "system/sales-channels",
        component: AdminSalesChannels,
        meta: {
          title: "sales-channels",
          layout: "admin"
        }
      },
      {
        name: "AdminCategories",
        path: "system/categories",
        component: AdminCategories,
        meta: {
          title: "categories",
          layout: "admin"
        }
      },
      {
        name: "AdminLanguages",
        path: "system/languages",
        component: AdminLanguages,
        meta: {
          title: "languages",
          layout: "admin"
        }
      },
      {
        name: "AdminCurrencies",
        path: "system/currencies",
        component: AdminCurrencies,
        meta: {
          title: "currencies",
          layout: "admin"
        }
      },
      {
        name: "AdminTerritories",
        path: "system/territories",
        component: AdminTerritories,
        meta: {
          title: "territories",
          layout: "admin"
        }
      },
      {
        name: "AdminViewPromotions",
        path: "promotions",
        component: ViewPromotions,
        meta: {
          title: "promotions",
          layout: "admin"
        }
      },
      {
        name: "AdminAddPromotion",
        path: "promotions/add",
        component: AddPromotion,
        meta: {
          title: "new-promotion",
          layout: "admin"
        }
      },
      {
        name: "AdminEditPromotion",
        path: "promotions/:id",
        component: EditPromotion,
        meta: {
          title: "edit-promotion",
          layout: "admin"
        }
      },
      {
        name: "ViewSaleImports",
        path: "sales/imports",
        component: ViewSaleImports,
        meta: {
          title: "view-sale-imports",
          layout: "admin"
        }
      },
      {
        name: "ViewSaleRecords",
        path: "sales/records",
        component: ViewSaleRecords,
        meta: {
          title: "view-sale-records",
          layout: "admin"
        }
      },
      {
        name: "ViewDailySaleImports",
        path: "sales/daily-imports",
        component: ViewSaleImports,
        meta: {
          title: "view-daily-sale-imports",
          layout: "admin",
          daily: true
        }
      },
      {
        name: "ViewDailySaleRecords",
        path: "sales/daily-records",
        component: ViewSaleRecords,
        meta: {
          title: "view-daily-sale-records",
          layout: "admin",
          daily: true
        }
      },
      {
        name: "ViewRoyalties",
        path: "royalties",
        component: ViewRoyalties,
        meta: {
          title: "view-royalty-records",
          layout: "admin"
        }
      },
      {
        name: "ViewRoyaltyPayments",
        path: "royalties/payments",
        component: ViewRoyaltyPayments,
        meta: {
          title: "view-royalty-payments",
          layout: "admin"
        }
      },
      {
        name: "AddRoyalties",
        path: "royalties/add",
        component: AddRoyalties,
        meta: {
          title: "add-royalties",
          layout: "admin"
        }
      },
      {
        name: "ViewPayments",
        path: "payments",
        component: ViewPayments,
        meta: {
          title: "view-payments",
          layout: "admin"
        }
      },
      {
        name: "AddPayment",
        path: "payments/add",
        component: AddPayment,
        meta: {
          title: "add-payment",
          layout: "admin"
        }
      },
      {
        name: "AddBatchPayments",
        path: "payments/add-batch",
        component: AddBatchPayments,
        meta: {
          title: "add-batch-payments",
          layout: "admin"
        }
      },
      {
        name: "IsbnManager",
        path: "isbn-manager",
        component: ViewIsbns,
        meta: {
          title: "isbn-manager",
          layout: "admin"
        }
      },
      {
        name: "AdminSalesByChannel",
        path: "reports/sales-by-channel",
        component: () =>
          import(
            /* webpackChunkName: "admin-sales-by-channel" */ "@/views/client/SalesBySalesChannel"
          ),
        meta: {
          title: "sales-by-channel",
          layout: "admin"
        }
      },
      {
        name: "AdminSalesByTitle",
        path: "reports/sales-by-title",
        component: () =>
          import(
            /* webpackChunkName: "admin-sales-by-title" */ "@/views/client/SalesByTitle"
          ),
        meta: {
          title: "sales-by-title",
          layout: "admin"
        }
      },
      {
        name: "AdminSalesByTerritory",
        path: "reports/sales-by-territory",
        component: () =>
          import(
            /* webpackChunkName: "admin-sales-by-territory" */ "@/views/client/SalesByTerritory"
          ),
        meta: {
          title: "sales-by-territory",
          layout: "admin"
        }
      },
      {
        name: "AdminDailySalesReport",
        path: "reports/daily-sales-report",
        component: () =>
          import(
            /* webpackChunkName: "admin-daily-sales-report" */ "@/views/client/DailySalesReport"
          ),
        meta: {
          title: "daily-eBook-sales-report",
          layout: "admin"
        }
      },
      {
        name: "AdminRoyaltyReport",
        path: "reports/royalty-report",
        component: () =>
          import(
            /* webpackChunkName: "admin-royalty-report" */ "@/views/client/RoyaltyReport"
          ),
        meta: {
          title: "royalty-report",
          layout: "admin"
        }
      }
    ],
    "admin"
  );
};
