import api from "@/api";
("lodash-es");

export default {
  namespaced: true,
  actions: {
    getHomeSummary(_, pagination) {
      return api.$get("/admin-summaries", pagination).then(res => res.data);
    }
  }
};
