import api from "@/api";
import { forEach } from "lodash-es";

export default {
  namespaced: true,
  state: {
    processorTypes: [],
    dailyProcessorTypes: []
  },
  mutations: {
    SAVE_PROCESSOR_TYPES(state, processorTypes) {
      state.processorTypes = processorTypes;
      state.dailyProcessorTypes = processorTypes;
    }
  },
  actions: {
    get(_, pagination) {
      return api.$get("/daily-sale-imports", pagination).then(res => res.data);
    },
    getProcessorTypes({ state, commit }) {
      if (!state.processorTypes.length)
        api
          .get("/daily-sale-imports/processor-types")
          .then(res => res.data)
          .then(res => {
            commit("SAVE_PROCESSOR_TYPES", res.data);
          });
    },
    create(_, { salesImport, onUploadProgress }) {
      const fd = new FormData();
      forEach(salesImport, (val, key) => {
        fd.append(key, val);
      });
      return api
        .post("/daily-sale-imports", fd, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress
        })
        .then(res => res.data);
    },
    update(_, salesImport) {
      return api
        .put(`/daily-sale-imports/${salesImport.id}`, salesImport)
        .then(res => res.data);
    },
    delete(_, salesImportId) {
      return api
        .delete(`/daily-sale-imports/${salesImportId}`)
        .then(res => res.data);
    },
    confirm(_, salesImportId) {
      return api
        .put(`/daily-sale-imports/${salesImportId}/confirm`)
        .then(res => res.data);
    },
    cancel(_, salesImportId) {
      return api
        .put(`/daily-sale-imports/${salesImportId}/cancel`)
        .then(res => res.data);
    }
  }
};
