<template>
  <div class="proxy-bar">
    <global-alert>
      {{ $t("account-outstanding") }}
    </global-alert>
  </div>
</template>

<script>
import GlobalAlert from "@/components/core/global-alert";

export default {
  name: "proxy-bar",
  components: {
    GlobalAlert
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    goBack() {
      this.$store.commit("auth/setUserData", {
        user: this.$store.getters["proxy/user"],
        token: this.$store.getters["proxy/token"]
      });

      // Flush proxy
      this.$store.commit("proxy/clearUserData");
      this.$router.push({
        name: "AdminDashboard"
      });
    }
  }
};
</script>
