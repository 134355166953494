import api from "@/api";

export default {
  namespaced: true,
  actions: {
    getHomeSummary(_, year = null) {
      return api.$get("/reports/home/summary", { year }).then(res => res.data);
    },
    getHomeSalesByCategory(_, year = null) {
      return api
        .$get("/reports/home/sales-by-category", { year })
        .then(res => res.data);
    },
    getRoyaltiesPerMonth(_, year = null) {
      return api
        .$get("/reports/home/royalties-per-month", { year })
        .then(res => res.data);
    },
    salesByTerritory() {
      return api.get(`/reports/home/sales-by-territory`).then(res => res.data);
    },
    salesBySalesChannel(_, filters) {
      return api
        .$get(`/reports/sales-by-sales-channel`, filters, { noPrefix: true })
        .then(res => res.data);
    },
    salesByTitle(_, filters) {
      return api
        .$get(`/reports/sales-by-title`, filters, { noPrefix: true })
        .then(res => res.data);
    },
    titleSaleSummary(_, filters) {
      return api
        .$get(`/reports/title-sales-summary`, filters, { noPrefix: true })
        .then(res => res.data);
    },
    royaltiesByStatus(_, filters) {
      return api
        .$get(`/reports/royalties-by-status`, filters, { noPrefix: true })
        .then(res => res.data);
    },
    booksCount(_, status = "live") {
      return api.$get("/reports/books/count", { status }).then(res => res.data);
    },
    booksBySales(_, { status, limit }) {
      return api
        .$get("/reports/books/by-sales", { status, limit })
        .then(res => res.data);
    },

    salesPerformance(_, year = null) {
      year = year || new Date().getFullYear();
      return api
        .get(`/reports/sales/by-category/${year}`)
        .then(res => res.data);
    },
    exportCSV(_, filters) {
      return api
        .$get("/reports/sales/export", filters, {
          responseType: "blob",
          noPrefix: true
        })
        .then(res => res.data);
    },
    exportSalesByTitle(_, filters) {
      return api
        .$get("/reports/export-sales-by-title", filters, {
          responseType: "blob",
          noPrefix: true
        })
        .then(res => res.data);
    },
    titleSaleTotalFigures(_, bookId) {
      return api
        .$get("/reports/sales-figure-summary/" + bookId, {}, { noPrefix: true })
        .then(res => res.data);
    }
  }
};
