<template>
  <div id="app" :class="$route.name">
    <preloader v-if="ebpLoaderState" />
    <proxy-bar v-if="proxied" />
    <outstanding-bar v-if="user && user.is_outstanding" />
    <vue-extend-layouts v-show="!ebpLoaderState">
      <template #header>
        <PageHeader :items="breadcrumbItems" :title="$route.meta.title"
      /></template>
    </vue-extend-layouts>
    <breakpoint />
  </div>
</template>

<script>
import Breakpoint from "@/components/breakpoint";
import VueExtendLayouts from "@/layouts/layout-extender";
import PageHeader from "@/components/page-header";
import ProxyBar from "@/components/proxy-bar";
import OutstandingBar from "@/components/outstanding-bar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    VueExtendLayouts,
    PageHeader,
    Breakpoint,
    ProxyBar,
    OutstandingBar
  },
  metaInfo() {
    return {
      title: this.$t(this.$route.meta.title),
      titleTemplate: "%s - eBookPartnership"
    };
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    async checkToken() {
      if (this.user) {
        this.ebpLoader(true);
        try {
          // Will automatically logout if token expired
          await this.$store.dispatch("users/current");
        } catch (err) {
          console.log(err);
        }
      }
      this.$nextTick(() => this.ebpLoader(false));
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    breadcrumbItems() {
      const crumbs = this.$route.path
        .replace(/^\/|\/$/g, "")
        .split("/")
        .slice(1)
        .map(r => ({
          text: this.$t(r)
        }));

      return crumbs.length > 1 ? crumbs : [];
    }
  }
};
</script>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000 !important;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 5px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
