<template>
  <div class="ebp-modal">
    <sweet-modal
      v-bind="$props"
      ref="modal"
      @close="$emit('update:open', false)"
    >
      <slot v-if="!tabs.length" />

      <slot name="error" />

      <!-- Tabs -->
      <sweet-modal-tab
        v-for="(tab, i) in tabs"
        :id="tab"
        :key="i"
        :title="$t(tab)"
      >
        <slot :name="tab" />
      </sweet-modal-tab>

      <template #box-action>
        <slot name="actions" />
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

export default {
  name: "ebp-modal",
  props: {
    open: {
      type: null
    },
    tabs: {
      type: Array,
      default: () => []
    },
    width: {
      type: String
    }
  },
  data() {
    return {};
  },
  destroyed() {
    this.$emit("update:open", false);
  },
  components: {
    SweetModal,
    SweetModalTab
  },
  watch: {
    open(open) {
      if (open) {
        const tab = typeof open === "string" ? open : null;
        this.$refs.modal.open(tab);
      } else {
        this.$refs.modal.close();
      }
    }
  }
};
</script>

<style lang="scss">
.ebp-modal {
  label {
    text-align: left;
  }

  .sweet-modal-overlay {
    background: rgba(0, 0, 0, 0.8);
  }
}
</style>
