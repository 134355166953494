<template>
  <div class="global-alert">
    <p class="m-0">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "global-alert"
};
</script>

<style lang="scss">
.global-alert {
  width: 100%;
  min-height: 3rem;
  background: $danger;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  a {
    color: white;
    margin-left: 1.5rem;
  }
}
</style>
