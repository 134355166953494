import api from "@/api";

export default {
  namespaced: true,
  actions: {
    paginate(_, pagination) {
      return api
        .$get("/sales-channels/paginate", pagination, { noPrefix: true })
        .then(res => {
          return res.data;
        });
    },
    search(_, params) {
      return api
        .$get(
          "/sales-channels/paginate",
          { ...params, limit: 500, sort_order: "asc", sort_by: "name_length" },
          { noPrefix: true }
        )
        .then(res => res.data);
    },
    searchParents(_, params) {
      const newParams = { ...params, parent_only: true, limit: 500 };
      return api
        .$get("/sales-channels/paginate", newParams, { noPrefix: true })
        .then(res => res.data);
    },
    create(_, channel) {
      return api.post("/sales_channels", channel).then(res => res.data);
    },
    update(_, channel) {
      return api
        .put(`/sales_channels/${channel.id}`, channel)
        .then(res => res.data);
    },
    delete(_, channel_id) {
      return api.delete(`/sales_channels/${channel_id}`).then(res => res.data);
    }
  }
};
