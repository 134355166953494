export default {
  namespaced: true,
  state: {
    layoutType: "vertical",
    layoutWidth: "fluid",
    leftSidebarType: "dark",
    topbar: "dark",
    loader: true,
    isMenuCondensed: false
  },
  mutations: {
    CHANGE_LAYOUT(state, layoutType) {
      state.layoutType = layoutType;
    },
    CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
      state.layoutWidth = layoutWidth;
    },
    CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
      state.leftSidebarType = leftSidebarType;
    },
    CHANGE_TOPBAR(state, topbar) {
      state.topbar = topbar;
    },
    LOADER(state, loader) {
      state.loader = loader;
    },
    TOGGLE_MENU(state) {
      state.isMenuCondensed = !state.isMenuCondensed;
    }
  },
  getters: {
    menu(state) {
      return state.isMenuCondensed;
    },
    loader(state) {
      return state.loader;
    }
  },
  actions: {
    changeLayoutType({ commit }, { layoutType }) {
      commit("CHANGE_LAYOUT", layoutType);
    },

    changeLayoutWidth({ commit }, { layoutWidth }) {
      commit("CHANGE_LAYOUT_WIDTH", layoutWidth);
    },

    changeLeftSidebarType({ commit }, { leftSidebarType }) {
      commit("CHANGE_LEFT_SIDEBAR_TYPE", leftSidebarType);
    },

    changeTopbar({ commit }, { topbar }) {
      commit("CHANGE_TOPBAR", topbar);
    },

    changeLoaderValue({ commit }, { loader }) {
      commit("LOADER", loader);
    }
  }
};
