/* eslint-disable no-console */

import { register } from "register-service-worker";

const mode = process.env.NODE_ENV;
const shouldLog = false;

if (mode === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      if (shouldLog) {
        console.log(
          "App is being served from cache by a service worker.\n" +
            "For more details, visit https://goo.gl/AFskqB"
        );
      }
    },
    registered() {
      if (shouldLog) {
        console.log("Service worker has been registered.");
      }
    },
    cached() {
      if (shouldLog) {
        console.log("Content has been cached for offline use.");
      }
    },
    updatefound() {
      if (shouldLog) {
        console.log("New content is downloading.");
      }

      document.getElementById(
        "app"
      ).innerHTML = `<div class="app-loader"><p>Please wait while we configure the system. This page will automatically refresh in a few seconds</p>
<p>Click <a href="javascript:window.location.href=window.location.href" style="display: inline;">here</a> if the page doesn't automatically reload after a few seconds.</p></div>`;
    },
    updated(registration) {
      if (shouldLog) {
        console.log("New content is available; please refresh.");
      }
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
