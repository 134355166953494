<template>
  <div
    v-b-visible="handleVisible"
    class="position-fixed d-block d-md-none breakpoint"
  ></div>
</template>

<script>
export default {
  methods: {
    handleVisible(isVisible) {
      this.$store.commit("SET_IS_SMALL_SCREEN", isVisible);
    }
  }
};
</script>

<style lang="scss">
.breakpoint {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 1px;
  background: red;
}
</style>
