import commaNumber from "comma-number";

export default {
  methods: {
    formatNumber(amount) {
      amount = parseFloat(amount) || 0;
      return commaNumber(amount % 1 !== 0 ? amount.toFixed(2) : amount);
    },
    formatCurrency(
      amount,
      currencyCode = "GBP",
      fractions = 2,
      fractionsMin = 0
    ) {
      amount = amount || 0;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: fractionsMin,
        maximumFractionDigits: fractions
      }).format(amount);
    }
  }
};
