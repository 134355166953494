import { format, formatDistance, parseISO, endOfDay } from "date-fns";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";
export default {
  computed: {
    $timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  },
  methods: {
    getISO() {
      return format(endOfDay(new Date()), "yyyy-MM-dd[T]HH:mm:ss");
    },
    formatDate(timestamp, useFormat = null) {
      useFormat = useFormat || process.env.VUE_APP_DATE_FORMAT;
      if (!timestamp) {
        timestamp = Date.now();
      }

      const date = utcToZonedTime(this.toISOLocal(timestamp), this.$timezone);

      return format(date, useFormat);
    },
    stringToDate(string) {
      return format(string, "yyyy-MM-dd");
    },
    formatDateTime(timestamp, useFormat = null) {
      useFormat = useFormat || "HH:mm, " + process.env.VUE_APP_DATE_FORMAT;
      if (!timestamp) {
        return null;
      }
      return formatTz(
        utcToZonedTime(parseISO(timestamp), this.$timezone),
        useFormat,
        { timeZone: this.$timezone }
      );
    },
    toISOLocal(d) {
      d = new Date(d);
      var z = n => ("0" + n).slice(-2);
      var zz = n => ("00" + n).slice(-3);

      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      );
    },
    fromNow(timestamp) {
      if (!timestamp) {
        return null;
      }
      return (
        formatDistance(
          utcToZonedTime(this.toISOLocal(timestamp), this.$timezone),
          Date.now()
        ) + " ago"
      );
    }
  }
};
