import api from "@/api";

export default {
  namespaced: true,
  state: {
    user: null,
    token: null
  },

  mutations: {
    setUserData(state, { user, token }) {
      state.user = user;
      state.token = token;
    },

    clearUserData(state) {
      state.user = null;
      state.token = null;
      location.reload();
    }
  },

  actions: {
    login({ commit }, credentials) {
      return api
        .post("/auth/login", credentials)
        .then(res => res.data)
        .then(res => {
          commit("setUserData", res.data);
        });
    },

    register({ commit }, user) {
      return api
        .post("/auth/register", user)
        .then(res => res.data)
        .then(res => {
          commit("setUserData", res.data);
        });
    },

    logout({ commit }) {
      commit("clearUserData");
    }
  },

  getters: {
    isLogged: state => !!state.user,
    user: state => state.user,
    token: state => state.token
  }
};
