<template>
  <div
    class="back-button d-flex flex-sm-row flex-column justify-content-between"
  >
    <ebp-button @click="goBack" size="sm"
      ><i class="bx bx-arrow-back mr-2"></i>{{ $t("back") }}</ebp-button
    >
    <slot />
  </div>
</template>

<script>
export default {
  name: "back-button",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style></style>
