<template>
  <b-button
    v-bind="attrs"
    v-on="$listeners"
    :class="className"
    :disabled="loading"
    class="ebp-button"
  >
    <i
      class="bx bx-loader bx-spin font-size-16 align-middle mr-2"
      v-if="loading"
    ></i>
    <slot
  /></b-button>
</template>

<script>
export default {
  name: "ebp-button",
  props: {
    loading: Boolean,
    className: String
  },
  computed: {
    attrs() {
      return {
        variant: "primary",
        ...this.$attrs
      };
    }
  }
};
</script>
