import api from "@/api";

export default {
  namespaced: true,
  state: {
    siteSettings: {}
  },
  mutations: {
    SAVE_SITE_SETTINGS(state, siteSettings) {
      state.siteSettings = siteSettings;
    }
  },
  getters: {
    get: state => state.siteSetings,
    show: state => name => state.siteSetings[name]
  },
  actions: {
    get({ commit }, { exclude, include } = {}) {
      return api
        .$get("/site-settings", { exclude, include })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_SITE_SETTINGS", res.data);
          return res;
        });
    },
    update(_, siteSetings) {
      return api
        .put("/site-settings/update", siteSetings)
        .then(res => res.data);
    }
  }
};
