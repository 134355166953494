export default {
  methods: {
    statusVariant(status) {
      switch (status) {
        // Warning
        case "publish-requested":
        case "processing":
        case "received":
          return "warning";

        // Danger
        case "unpublish-requested":
        case "unpublished":
        case "admin":
        case "outstanding":
          return "danger";

        // Secondary
        case "requested":
        case "draft":
          return "secondary";

        // Success
        case "scheduled":
        case "completed":
        case "complete":
        case "live":
        case "active":
        case "client":
        case "paid":
          return "success";

        // Primary
        case "finished":
          return "primary";

        // Info
        case "rolled_back":
        case "in-review":
          return "info";

        default:
          return "primary";
      }
    }
  }
};
