import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

import auth from "@/store/modules/auth";
import proxy from "@/store/modules/proxy";
import forgot_password from "@/store/modules/forgot_password";
import layout from "@/store/modules/layout";
import books from "@/store/modules/books";
import promotions from "@/store/modules/promotions";
import error from "@/store/modules/error";
import ancillaries from "@/store/modules/ancillaries";

import salesChannels from "@/store/modules/ancillaries/salesChannels";
import categories from "@/store/modules/ancillaries/categories";
import languages from "@/store/modules/ancillaries/languages";
import territories from "@/store/modules/ancillaries/territories";
import currencies from "@/store/modules/ancillaries/currencies";

import saleRecords from "@/store/modules/saleRecords";
import saleImports from "@/store/modules/saleImports";

// Daily sales
import dailySaleImports from "@/store/modules/dailySaleImports";
import dailySaleRecords from "@/store/modules/dailySaleRecords";

import reports from "@/store/modules/reports";

import royalties from "@/store/modules/royalties";
import inboundPayments from "@/store/modules/inboundPayments";
import outboundPayments from "@/store/modules/outboundPayments";
import userPreferences from "@/store/modules/userPreferences";

import isbns from "@/store/modules/isbns";

// Admin modules
import users from "@/store/modules/users";
import adminHome from "@/store/modules/adminHome";

// Ancillaries
import authors from "@/store/modules/authors";

import siteSettings from "@/store/modules/siteSettings";

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["auth", "proxy"]
    })
  ],
  state: {
    isSmallScreen: false
  },
  mutations: {
    SET_IS_SMALL_SCREEN(state, isSmallScreen) {
      state.isSmallScreen = isSmallScreen;
    }
  },
  getters: {
    getIsSmallScreen(state) {
      return state.isSmallScreen;
    }
  },
  actions: {},
  modules: {
    auth,
    forgot_password,
    layout,
    books,
    error,
    ancillaries,
    authors,
    users,
    salesChannels,
    categories,
    languages,
    currencies,
    promotions,
    saleImports,
    saleRecords,
    proxy,
    reports,
    royalties,
    siteSettings,
    territories,
    inboundPayments,
    outboundPayments,
    userPreferences,
    isbns,
    dailySaleImports,
    dailySaleRecords,
    adminHome
  }
});
