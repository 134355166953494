import api from "@/api";
import { forEach } from "lodash-es";

export default {
  namespaced: true,
  actions: {
    get(_, pagination) {
      return api.$get("/isbns", pagination).then(res => res.data);
    },
    create(_, { isbn, onUploadProgress }) {
      const fd = new FormData();
      forEach(isbn, (val, key) => {
        fd.append(key, val);
      });
      return api
        .post("/isbns", fd, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress
        })
        .then(res => res.data);
    },
    update(_, isbn) {
      return api.put(`/isbns/${isbn.id}`, isbn).then(res => res.data);
    },
    delete(_, isbn_id) {
      return api.delete(`/isbns/${isbn_id}`).then(res => res.data);
    },
    unassign(_, isbn_id) {
      return api.put(`/isbns/${isbn_id}/unassign`).then(res => res.data);
    }
  }
};
