// Guest routes
const Login = () => import(/* webpackChunkName: "login" */ "@/views/Login");
const Register = () =>
  import(/* webpackChunkName: "register" */ "@/views/Register");
const ForgotPassword = () =>
  import(/* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword");

export default [
  {
    name: "Login",
    path: "/login",
    alias: "/",
    component: Login,
    meta: {
      title: "login",
      layout: "auth",
      guest: true
    }
  },
  {
    name: "Register",
    path: "/register",
    component: Register,
    meta: {
      title: "register",
      layout: "auth",
      guest: true
    }
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
    meta: {
      title: "forgot-password",
      layout: "auth",
      guest: true
    }
  }
];
