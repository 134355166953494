import api from "@/api";

export default {
  namespaced: true,
  actions: {
    get(_, pagination) {
      return api.$get("/users", pagination).then(res => res.data);
    },
    getPaymentPreferences() {
      return api.get("/users/payment-method").then(res => res.data);
    },
    search(_, params) {
      return api
        .$get("/users", {
          ...params
        })
        .then(res => res.data);
    },
    create(_, user) {
      return api.post("/users", user).then(res => res.data);
    },
    update(_, user) {
      return api.put(`/users/${user.id}`, user).then(res => res.data);
    },
    delete(_, id) {
      return api.delete(`/users/${id}`).then(res => res.data);
    },
    changePassword(_, { id, new_password }) {
      return api
        .put(`/users/${id}/change-password`, { new_password })
        .then(res => res.data);
    },
    resetLegacyPassword(_, { oldPassword, newPassword }) {
      return api
        .put("/reset-legacy-password", { oldPassword, newPassword })
        .then(res => res.data);
    },
    current() {
      return api.get("/user", { noPrefix: true }).then(res => res.data);
    },
    updateCurrent(_, user) {
      return api.put(`/users/me`, user).then(res => res.data);
    }
  }
};
