<template>
  <div class="spinner-dots">
    <div id="status">
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "spinner-dots"
};
</script>

<style>
.spinner-dots {
  position: relative;
}
</style>
