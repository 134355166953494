import api from "@/api";

export default {
  namespaced: true,
  actions: {
    index(_, params) {
      return api.$get(`/authors`, params).then(res => res.data);
    },
    get(_, book_id) {
      return api.get(`/books/${book_id}/authors`).then(res => res.data);
    },
    create(_, { book_id, author }) {
      return api
        .post(`/books/${book_id}/authors`, author)
        .then(res => res.data);
    },
    update(_, { book_id, author }) {
      return api
        .put(`/books/${book_id}/authors/${author.id}`, author)
        .then(res => res.data);
    },
    delete(_, { book_id, author_id }) {
      return api
        .delete(`/books/${book_id}/authors/${author_id}`)
        .then(res => res.data);
    }
  }
};
