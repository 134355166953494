import api from "@/api";

export default {
  namespaced: true,
  state: {
    requestedCount: 0
  },
  getters: {
    getRequested(state) {
      return state.requestedCount ? state.requestedCount.toString() : null;
    }
  },
  mutations: {
    SAVE_REQUESTED_COUNT(state, requestedCount) {
      state.requestedCount = requestedCount;
    }
  },
  actions: {
    getRequestedCount({ commit }) {
      api
        .get("/promotions/requested-count")
        .then(res => res.data)
        .then(res => commit("SAVE_REQUESTED_COUNT", res.data));
    },
    get(_, pagination) {
      return api.$get("/promotions", pagination).then(res => res.data);
    },
    show(_, id) {
      return api.get(`/promotions/${id}`).then(res => res.data);
    },
    create(_, promotion) {
      return api.post("/promotions", promotion).then(res => res.data);
    },
    update(_, promotion) {
      return api
        .put(`/promotions/${promotion.id}`, promotion)
        .then(res => res.data);
    },
    delete(_, promotion_id) {
      return api.delete(`/promotions/${promotion_id}`).then(res => res.data);
    }
  }
};
