import api from "@/api";

export default {
  namespaced: true,
  state: {
    currencies: [],
    currenciesObj: {}
  },
  mutations: {
    SAVE_CURRENCIES(state, currencies) {
      state.currencies = currencies;
      const currenciesObj = {};
      currencies.map(c => (currenciesObj[c.code] = currenciesObj));
    }
  },
  actions: {
    get({ commit }) {
      return api
        .get("/currencies", { noPrefix: true })
        .then(res => res.data)
        .then(res => {
          commit("SAVE_CURRENCIES", res.data);
          return res;
        });
    },
    paginate(_, pagination) {
      return api
        .$get("/currencies/paginate", pagination, { noPrefix: true })
        .then(res => res.data);
    },
    search(_, params) {
      return api
        .$get("/currencies/paginate", params, { noPrefix: true })
        .then(res => res.data);
    },
    create(_, channel) {
      return api.post("/currencies", channel).then(res => res.data);
    },
    update(_, channel) {
      return api
        .put(`/currencies/${channel.id}`, channel)
        .then(res => res.data);
    },
    delete(_, channel_id) {
      return api.delete(`/currencies/${channel_id}`).then(res => res.data);
    }
  }
};
