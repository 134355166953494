export default {
  computed: {
    gError: {
      get() {
        return this.$store.getters["error/get"];
      },
      set(err) {
        this.setError(this.errorRes(err));
      }
    }
  },
  methods: {
    errorRes(err) {
      if (typeof err === "string") {
        return this.$t(err);
      }
      return err.response
        ? err.response.data.translation || err.response.data.message || err
        : err;
    },
    successRes(res) {
      return this.$t(res.data.message || "success");
    },
    setError(err) {
      this.$store.dispatch("error/set", {
        err: this.errorRes(err)
      });
    }
  }
};
