const LegacyPasswordReset = () =>
  import(
    /* webpackChunkName: "legacy-password-reset" */ "@/views/client/LegacyPasswordReset"
  );

// Client routes
const Dashboard = () =>
  import(/* webpackChunkName: "client-dashboard" */ "@/views/client/Dashboard");

// Titles
const ViewBooks = () =>
  import(
    /* webpackChunkName: "clien-view-books" */ "@/views/client/books/View"
  );
const AddBook = () =>
  import(/* webpackChunkName: "clien-add-book" */ "@/views/client/books/Add");

const ViewBook = () =>
  import(
    /* webpackChunkName: "client-view-book" */ "@/views/client/books/ViewBook"
  );
const EditBook = () =>
  import(/* webpackChunkName: "clien-edit-book" */ "@/views/client/books/Edit");

// Promotions
const ViewPromotions = () =>
  import(
    /* webpackChunkName: "view-promotions" */ "@/views/client/promotions/View"
  );
const AddPromotion = () =>
  import(
    /* webpackChunkName: "add-promotion" */ "@/views/client/promotions/Add"
  );
const EditPromotion = () =>
  import(
    /* webpackChunkName: "edit-promotion" */ "@/views/client/promotions/Edit"
  );

// Payment routes
const PaymentHistory = () =>
  import(
    /* webpackChunkName: "payment-history" */ "@/views/client/payments/History"
  );
const ViewRoyalties = () =>
  import(
    /* webpackChunkName: "client-royalties-view" */ "@/views/client/royalties/View"
  );

// Account
const MyProfile = () =>
  import(/* webpackChunkName: "my-profile" */ "@/views/client/MyProfile");

// Support
const Support = () =>
  import(/* webpackChunkName: "support" */ "@/views/client/Support");

// Daily sales report
const DailySalesReport = () =>
  import(
    /* webpackChunkName: "daily-sales-report" */ "@/views/client/DailySalesReport"
  );

export default prefixRoutes => {
  return prefixRoutes(
    "/client",
    [
      {
        name: "LegacyPasswordReset",
        path: "reset-old-password",
        component: LegacyPasswordReset,
        meta: {
          title: "reset-old-password",
          layout: "auth"
        }
      },
      {
        name: "ClientDashboard",
        path: "",
        component: Dashboard,
        meta: {
          title: "dashboard",
          layout: "client"
        }
      },
      {
        name: "ViewBooks",
        path: "books",
        component: ViewBooks,
        meta: {
          title: "my-books",
          layout: "client"
        }
      },
      {
        name: "AddBook",
        path: "books/add",
        component: AddBook,
        meta: {
          title: "create-new-book",
          layout: "client"
        }
      },
      {
        name: "ViewBook",
        path: "books/view/:id",
        component: ViewBook,
        meta: {
          title: "Book Summary",
          layout: "client"
        }
      },
      {
        name: "EditBook",
        path: "books/edit/:id",
        component: EditBook,
        meta: {
          title: "edit-book",
          layout: "client"
        }
      },
      {
        name: "ViewPromotions",
        path: "promotions",
        component: ViewPromotions,
        meta: {
          title: "view-promotions",
          layout: "client"
        }
      },
      {
        name: "AddPromotion",
        path: "promotions/add",
        component: AddPromotion,
        meta: {
          title: "new-promotion",
          layout: "client"
        }
      },
      {
        name: "EditPromotion",
        path: "promotions/:id",
        component: EditPromotion,
        meta: {
          title: "edit-promotion",
          layout: "client"
        }
      },
      {
        name: "PaymentHistory",
        path: "payments/history",
        component: PaymentHistory,
        meta: {
          title: "payment-history",
          layout: "client"
        }
      },
      {
        name: "ClientViewRoyalties",
        path: "royalties",
        component: ViewRoyalties,
        meta: {
          title: "view-royalties",
          layout: "client"
        }
      },
      {
        name: "MyProfile",
        path: "profile",
        component: MyProfile,
        meta: {
          title: "my-profile",
          layout: "client"
        }
      },
      {
        name: "Support",
        path: "support",
        component: Support,
        meta: {
          title: "Support",
          layout: "client"
        }
      },
      {
        name: "DailySalesReport",
        path: "daily-sales",
        component: DailySalesReport,
        meta: {
          title: "daily-sales-report",
          layout: "client"
        }
      },
      {
        name: "SalesBySalesChannel",
        path: "sales-by-sales-channel",
        component: () =>
          import(
            /* webpackChunkName: "sales-by-sales-channel" */ "@/views/client/SalesBySalesChannel"
          ),
        meta: {
          title: "sales-by-channel",
          layout: "client"
        }
      },
      {
        name: "SalesByTitle",
        path: "sales-by-title",
        component: () =>
          import(
            /* webpackChunkName: "sales-by-title" */ "@/views/client/SalesByTitle"
          ),
        meta: {
          title: "sales-by-title",
          layout: "client"
        }
      },
      {
        name: "SalesByTerritory",
        path: "sales-by-territory",
        component: () =>
          import(
            /* webpackChunkName: "sales-by-territory" */ "@/views/client/SalesByTerritory"
          ),
        meta: {
          title: "sales-by-territory",
          layout: "client"
        }
      },
      {
        name: "RoyaltyReport",
        path: "royalty-report",
        component: () =>
          import(
            /* webpackChunkName: "royalty-report" */ "@/views/client/RoyaltyReport"
          ),
        meta: {
          title: "royalty-report",
          layout: "client"
        }
      }
    ],
    "client"
  );
};
